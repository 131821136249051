import Vue from "vue";
import VueRouter from "vue-router";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        requiresAuth: false,
        layout: "full",
      },
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/views/dashboard/index.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard/index.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/customer",
      name: "customer",
      component: () => import("@/views/customer/CustomerList.vue"),
      meta: {
        pageTitle: "Customer",
        breadcrumb: [
          {
            text: "Customer",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/customer/:id",
      name: "customer-detail",
      component: () => import("@/views/customer/CustomerDetail.vue"),
      meta: {
        pageTitle: "Detail",
        breadcrumb: [
          {
            text: "Customer",
            to: "/customer",
          },
          {
            text: "Detail",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/setting",
      name: "setting",
      component: () => import("@/views/customer/CustomerDetail.vue"),
      meta: {
        pageTitle: "Setting",
        breadcrumb: [
          {
            text: "Setting",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/report",
      name: "report",
      component: () => import("@/views/report/"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report/member-summary",
      name: "member-summary",
      component: () => import("@/views/report/MemberSum.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report/checkin/member-summary",
      name: "checkin-member-summary",
      component: () => import("@/views/report/MemberSumCheckin.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/report/member/:id",
      name: "chart-report",
      component: () => import("@/views/report/chart/index.vue"),
      meta: {
        // pageTitle: "chart",

        requiresAuth: true,
      },
    },
    {
      path: "/device",
      name: "device",
      component: () => import("@/views/device/"),


      meta: {
        requiresAuth: true,
      },
    },

    // {
    //   path: "/report/member/:id",
    //   name: "report",
    //   component: () => import("@/views/report/"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },


    {
      path: "/product",
      name: "product",
      component: () => import("@/views/product/Product.vue"),
      meta: {
        pageTitle: "Product",
        breadcrumb: [
          {
            text: "Product",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/UsersList.vue"),
      meta: {
        pageTitle: "Users",
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: "/get-link-redirect",
      name: "link_redirect",
      component: () => import("@/views/ParamRedirect.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   next("/login");
// });

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const routerName = to.name;
  var token = localStorage.getItem("token");
  var decoded = "";
  if (token) {
    try {
      decoded = jwt_decode(token);
    } catch (error) {
      localStorage.removeItem("token");
    }
  }

  if (requiresAuth && !token) {
    next("/login");
  } else {
    
      if (routerName == "home") {
        if (decoded.role == "superadmin" || decoded.role == "admin") {
          next("/report");
        } else {
          next();
        }
      } else if (routerName == "customer") {
        if (decoded.role != "superadmin" && decoded.role != "admin") {
          next("/setting");
        } else {
          next();
        }
      }  else {
        next();
      }
  }
  //   next("/login");
  // const routerName = to.name;
  // const requiresAuth = to.meta.requiresAuth;
  // next("/login");
  // var token = localStorage.getItem("token");
  // var decoded = "";
  // if (token) {
  //   try {
  //     decoded = jwt_decode(token);
  //   } catch (error) {
  //     localStorage.removeItem("token");
  //   }
  // }
  // if (requiresAuth && !token) {
  //   next("/login");
  // } else if (requiresAuth && token) {
  //   if (
  //     routerName == "users" ||
  //     routerName == "product" ||
  //     routerName == "customer"
  //   ) {
  //     if (decoded.role == "admin" || decoded.role == "2") {
  //       next("/report");
  //     } else {
  //       next();
  //     }
  //   } else if (routerName == "setting") {
  //     if (decoded.role == "superadmin" || decoded.role == "2") {
  //       next("/report");
  //     } else {
  //       next();
  //     }
  //   } else if (routerName == "customer-detail") {
  //     if (decoded.role == "admin" || decoded.role == "2") {
  //       next("/report");
  //     } else {
  //       next();
  //     }
  //   } else {
  //     next();
  //   }
  // } else {
  //   next();
  // }
});

// router.beforeEach((to, from, next) => {
//   const routerName = to.name;
//   const requiresAuth = to.meta.requiresAuth;

//   var token = localStorage.getItem("token");
//   var decoded = "";
//   if (token) {
//     try {
//       decoded = jwt_decode(token);
//     } catch (error) {
//       localStorage.removeItem("token");
//     }
//   }

//   next("/login");

//   // if (requiresAuth && !token) {
//   //   next("/login");
//   // } else if (requiresAuth && token) {
//   //   if (
//   //     routerName == "users" ||
//   //     routerName == "product" ||
//   //     routerName == "customer"
//   //   ) {
//   //     if (decoded.role == "admin" || decoded.role == "2") {
//   //       next("/report");
//   //     } else {
//   //       next();
//   //     }
//   //   } else if (routerName == "setting") {
//   //     if (decoded.role == "superadmin" || decoded.role == "2") {
//   //       next("/report");
//   //     } else {
//   //       next();
//   //     }
//   //   } else if (routerName == "customer-detail") {
//   //     if (decoded.role == "admin" || decoded.role == "2") {
//   //       next("/report");
//   //     } else {
//   //       next();
//   //     }
//   //   } else {
//   //     next();
//   //   }
//   // } else {
//   //   next();
//   // }
// });

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach((to, from, next) => {
//   // Remove initial loading
//   const appLoading = document.getElementById("loading-bg");
//   if (appLoading) {
//     appLoading.style.display = "none";
//   }
// });

export default router;
